<template>
  <div class="file-preview box sm">
    <div class="d-flex justify-content-between align-items-center mt-2 file-preview-item" v-for="(item, i) in fileList" :key="i">
      <div class="align-items-center align-self-stretch d-flex justify-content-center thumb">
        <img :src="item.url" class="img-fit">
      </div>
      <div class="remove">
        <button class="btn btn-sm btn-link remove-attachment" @click="removeFile(i)" type="button"><i class="la la-close"></i></button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {

    }
  },
  props: {
    fileList: Array
  },
  methods: {
    removeFile(i) {
      this.$emit('remove', i)
    }
  }
}
</script>